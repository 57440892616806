import { Organization } from '@icepanel/platform-api-client'

export default (status: Organization['status']) => {
  switch (status) {
    case 'trialing':
      return {
        color: 'info',
        text: 'Trial',
        textColor: 'grey darken-1'
      } as const

    case 'active':
      return {
        color: 'success',
        text: 'Active',
        textColor: 'white'
      } as const

    case 'canceled':
      return {
        color: 'error',
        text: 'Cancelled',
        textColor: 'white'
      } as const

    case 'incomplete_expired':
      return {
        color: 'error',
        text: 'Expired',
        textColor: 'white'
      } as const

    case 'incomplete':
      return {
        color: 'warning',
        text: 'Incomplete payment',
        textColor: 'white'
      } as const

    case 'unpaid':
      return {
        color: 'warning',
        text: 'Unpaid',
        textColor: 'white'
      } as const

    case 'past_due':
      return {
        color: 'warning',
        text: 'Past due',
        textColor: 'white'
      } as const
  }
}
