
import { FormController, IVForm, validationRules } from '@icepanel/app-form'
import { Organization } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { AlertModule } from '@/modules/alert/store'

import * as analytics from '../helpers/analytics'
import statusChip from '../helpers/status-chip'
import { OrganizationModule } from '../store'

@Component({
  name: 'OrganizationBilling'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)

  @Ref() readonly emailForm!: IVForm
  @Ref() readonly emailInput!: HTMLElement

  loadingBillingLink = false

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.$params.organizationId)!
  }

  get statusChip () {
    return statusChip(this.currentOrganization.status)
  }

  get currencies (): { text: string, value: Organization['billingCurrency'] }[] {
    return [
      {
        text: 'US Dollars',
        value: 'usd'
      },
      {
        text: 'Euros',
        value: 'eur'
      },
      {
        text: 'Canadian Dollars',
        value: 'cad'
      },
      {
        text: 'British Pounds',
        value: 'gbp'
      }
    ]
  }

  emailFormController = new FormController({
    initialModel: {
      billingEmail: ''
    },
    validationRules: {
      billingEmail: [
        ...validationRules.exists,
        ...validationRules.email
      ]
    }
  })

  created () {
    this.emailFormController.submitHandler = async model => {
      await this.organizationModule.updateOrganization({
        organizationId: this.currentOrganization.id,
        props: {
          billingEmail: model.billingEmail
        }
      })

      analytics.organizationUpdate.track(this, {
        organizationBillingEmail: model.billingEmail,
        organizationId: [this.currentOrganization.id]
      })
    }
  }

  mounted () {
    this.emailFormController.setInitialModel({
      billingEmail: this.currentOrganization.billingEmail || ''
    })
    this.emailFormController.resetModel()

    analytics.organizationBillingScreen.track(this, {
      organizationId: [this.currentOrganization.id]
    })

    this.emailFormController.disabled = true
    this.emailFormController.on('success', () => {
      this.emailFormController.disabled = true
    })
  }

  async editBilling (action?: 'update-subscription-plan-url') {
    try {
      if (this.loadingBillingLink) {
        return
      }
      this.loadingBillingLink = true

      const returnUrl = new URL(window.location.href)
      returnUrl.searchParams.set('billing_organization', this.currentOrganization.id)

      const links = await this.organizationModule.organizationBillingLinkCreate({
        organizationId: this.currentOrganization.id,
        returnUrl: returnUrl.toString()
      })

      analytics.organizationBillingLink.track(this, {
        organizationId: [this.currentOrganization.id]
      })

      await new Promise(resolve => setTimeout(resolve, 1000))

      window.location.href = action === 'update-subscription-plan-url' && links.updateSubscriptionPlanUrl ? links.updateSubscriptionPlanUrl : links.url
    } catch (err: any) {
      this.loadingBillingLink = false
      this.alertModule.pushAlert({
        color: 'error',
        message: err.body.message
      })
    }
  }

  get emailFormIcon () {
    if (this.emailFormController.submitting) {
      return undefined
    } else if (this.emailFormController.disabled) {
      return '$fas-pencil-alt'
    } else {
      return '$fas-check'
    }
  }

  emailFormSubmit () {
    if (!this.emailFormController.submitting) {
      if (this.emailFormController.disabled) {
        this.emailFormController.disabled = false
        this.emailInput.focus()
      } else if (this.currentOrganization.billingEmail === this.emailFormController.model.billingEmail) {
        this.emailFormController.disabled = true
      } else {
        this.emailFormController.submit(this.emailForm)
      }
    }
  }
}
